<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import {paginationHelper} from "@/helpers/pagination-helper";
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import {orderHelper} from "@/helpers/order-helper";
import OrderAddressButton from "@/components/core/order-address-button.vue";

export default {

  components: {
    OrderAddressButton,
    Layout,
    PageHeader
  },

  computed: {
    orderHelper() {
      return orderHelper
    },

    dateUtil() {
      return dateUtil
    },

    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: "",
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: ""
      },

      carriersIds: null,
      carriers: null,
      deliveryAddresses: new Map(),

      shopOrders: null,
      shopOrderProducts: null,
      shopOrderProductStatuses: null,
      shopOrderProductStatusTimes: null,

      filtering: {
        showStatus: "ALL"
      },

      modals: {
        changeProductStatus: {
          productIds: []
        }
      },

      customerId: "",
      customer: null,
      customerDetails: null,
      statuses: [
          "ALL",
          "DELETED",
          "ARCHIVED"
      ]
    }
  },

  methods: {

    highlightRow(item) {
      if (!item) {
        return ''
      }

      return this.modals.changeProductStatus.productIds.includes(item.id) ? 'highlighted-order' : 'default-order';
    },

    getOrderDetailsUrl(shopOrder) {
      return `/dashboard/core/order/details?shopOrderId=${shopOrder.id}&customerId=${this.customerId}`;
    },

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getIdText(item) {
      if (!item) {
        return ''
      }

      let id = item.idAsNumber + " "
      if (item.allegroAccountName) {
        id += " / " + '(' + item.allegroAccountName + ')'
      }

      id += " / " + dateUtil.asDateTimeNormal(item.createdAt)

      if (item.shopDomain) {
        id += " / " + item.shopDomain
      } else if (item.shopName) {
        id += " / " + item.shopName
      }

      return id
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('customers.core-orders.title', { 'firstName': this.customerDetails?.firstName || '', 'lastName': this.customerDetails?.lastName || '', 'email': this.customer?.email || '' }),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "id", label: 'ID'},
        {key: "pricesSum", label: 'Wartość zam. brutto', formatter: value => value.toFixed(2) + " PLN"},
        {key: "totalProductsCostTaxExcluded", label: 'Przychód (netto)', formatter: value => value.toFixed(2) + " PLN"},
        {key: 'lastModification', label: 'Ostatnia modyfikacja'},
        {key: "paymentStatus", label: 'Płatność'},
        {key: "action", label: this.$t('table.actions')}
      ]
    },

    getProductsFields() {
      return [
        {key: "name", label: 'Produkt'},
        {key: "quantity", label: 'Ilość'},
        {
          key: "wholesalePrice", label: 'Cena jedn. hurtowa netto', formatter: value => {
            if (!value) {
              return "-"
            }

            return value.toFixed(2) + " PLN"
          }
        },
        {key: "totalValueTaxExcluded", label: 'Cena jedn. netto', formatter: value => value.toFixed(2) + " PLN"},
        {key: "totalValueTaxIncluded", label: 'Cena jedn. brutto', formatter: value => value.toFixed(2) + " PLN"},
        {key: "warehouse", label: 'Hurtownia'},
        {key: "status", label: 'Status'}
      ]
    },

    getOrderProducts(shopOrder) {
      const products = this.shopOrderProducts[shopOrder.id]
      return products ? products : []
    },

    getProductLeftLabelColor(shopOrder) {
      const products = this.getOrderProducts(shopOrder);
      if (!products || products.length === 0) {
        return ''
      }

      const product = products[0];
      if (!product) {
        return ''
      }

      const currentTime = new Date().getTime();
      const status = this.getShopOrderProductTime(product.shopOrderProductStatus)
      const diff = currentTime - new Date(shopOrder.createdAt).getTime();

      if (status && status.time > 0 && diff > status.time) {
        return 'status-background-11'
      }

      return orderHelper.getProductTextStatusBackgroundColor(product.shopOrderProductStatus)
    },

    getProductRightLabelColor(shopOrder) {
      const products = this.getOrderProducts(shopOrder);
      if (!products || products.length === 0) {
        return ''
      }

      const product = products[0];
      if (!product) {
        return ''
      }

      return orderHelper.getProductTextStatusBackgroundColor(product.shopOrderProductStatus)
    },

    paymentStatusText(paymentStatus) {
      if (paymentStatus === "reset-option") {
        return "Wszystkie statusy"
      }

      switch (paymentStatus) {
        case 'WAITING':
          return 'Do weryfikacji'
        case 'SUCCESS':
          return 'Opłacona'
        case 'FAILED':
          return 'Nieopłacona'
      }
    },

    paymentStatusColor(paymentStatus) {
      switch (paymentStatus) {
        case 'WAITING':
          return 'badge-soft-warning'
        case 'SUCCESS':
          return 'badge-soft-success'
        case 'FAILED':
          return 'badge-soft-danger'
      }
    },

    async loadShopOrders() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/shop-order/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter,
            customerId: this.customerId,
            ...this.filtering
          }
        });

        this.shopOrders = data.shopOrders
        this.table.totalRows = data.count;
        this.table.rows = data.count;

        await this.loadShopOrderProducts()
        await this.loadDeliveryAddresses()

        try {
          paginationHelper.expandAll(this, this.shopOrders);
        } catch (error) {
          console.log(error)
        }
      } catch (error) {
        this.shopOrders = []
        this.table.totalRows = 0;
        this.table.rows = 0;
      }

      return this.shopOrders
    },

    async loadShopOrderProducts() {
      if (this.shopOrders.length === 0) {
        return;
      }

      const ids = [];
      for (let shopOrder of this.shopOrders) {
        ids.push(shopOrder.id);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/shop-order/product/fetch/by/shop-order`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.shopOrderProducts = {};

      for (const id of ids) {
        const orderProducts = []

        for (const orderProduct of result.data) {
          if (orderProduct.shopOrderId === id) {
            orderProducts.push(orderProduct)
          }
        }

        this.shopOrderProducts[id] = orderProducts
      }
    },

    async loadDeliveryAddresses() {
      try {
        if (this.shopOrders.length === 0) {
          return;
        }

        const ids = this.shopOrders.map(shopOrder => shopOrder.id);

        const {data} = await axios.get(`/shop-order/delivery-addresses`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            shopOrderIds: ids.join(",")
          }
        });

        this.deliveryAddresses = new Map(data.map((obj) => [obj.shopOrderId, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadShopOrderProductStatus() {
      try {
        const { data } = await axios.get(`/shop-order/product/status`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shopOrderProductStatuses = data
        await this.loadShopOrderProductStatusTimes()
      } catch (error) {
        console.log(error)
      }
    },

    async loadShopOrderProductStatusTimes() {
      try {
        const {data} = await axios.get(`/shop-order/product/status/times`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shopOrderProductStatusTimes = new Map(data.map((obj) => [obj.status, obj]));
      } catch (error) {
        console.log(error)
      }
    },

    getShopOrderProductTime(statusType) {
      if (!this.shopOrderProductStatusTimes) {
        return null
      }

      return this.shopOrderProductStatusTimes.get(statusType) || null
    },

    archiveOrUnArchiveOrder(order, archived) {
      const archivedOrder = Object.assign({}, order)
      archivedOrder.archived = archived
      delete archivedOrder._showDetails

      const json = JSON.stringify(archivedOrder);

      axios.put(`/shop-order`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(archived ? 'Zamówienie zarchiwizowane' : "Zamówienie przywrócone", {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    deleteOrder(order, deleted) {
      const deletedOrder = Object.assign({}, order)
      deletedOrder.deleted = deleted
      delete deletedOrder._showDetails

      const json = JSON.stringify(deletedOrder);

      axios.put(`/shop-order`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(deleted ? "Zamówienie usunięte" : "Zamówienie przywrócone", {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    changeProductStatus() {
      if (this.modals.changeProductStatus.productIds.length === 0) {
        this.$bvToast.toast('Wybierz przynajmniej jeden produkt!', {
          title: this.$t('message.error'),
          variant: 'danger',
          solid: true
        });

        return;
      }

      swalHelper.yesOrNoWithSubTitle(() => {
        this.$v.modals.changeProductStatus.$touch();

        if (this.$v.modals.changeProductStatus.$invalid) {
          return;
        }

        const json = JSON.stringify({
          "ids": this.modals.changeProductStatus.productIds,
          "status": this.modals.changeProductStatus.status
        })

        axios.post(`/shop-order/product/update-status`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(() => {
          this.$bvToast.toast('Pomyślnie zmieniono statusy produktów', {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, false)
    },

    async loadCustomer() {
      this.customerId = this.$route.params.customerId

      try {
        const { data } = await axios.get(`/customer/fetch`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            customerId: this.$route.params.customerId
          }
        });

        this.customer = data
        await this.loadCustomerDetails()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadCustomerDetails() {
      try {
        const { data } = await axios.get(`/customer/details/${this.customer.detailsId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.customerDetails = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getTextOr(translation, value) {
      if (value === "reset-option") {
        return "Wszystkie statusy"
      }

      return this.$t(translation + value)
    },

  },

  async beforeMount() {
    try {
      await this.loadCustomer()
      await this.loadShopOrderProductStatus()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('customers.core-orders.title', { 'firstName': customerDetails?.firstName || '', 'lastName': customerDetails?.lastName || '', 'email': customer?.email || '' })"/>

    <div class="card">
      <div class="card-body">
        <div class="py-1">
          <div class="row">
            <div class="col-lg-2 col-12">
              <vue-multiselect
                  @select="setToFirstPageAndRefresh"
                  v-model="filtering.showStatus"
                  :options="statuses"
                  :custom-label="value => getTextOr('shop-order.show-status.', value)"
                  placeholder="Wybierz status"
                  :show-labels="false"
              />
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  {{ $t('table.show') }}&nbsp;
                  <b-form-select v-model="table.perPage" size="sm"
                                 :options="table.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                </label>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  {{ $t('table.search') }}
                  <b-form-input v-model="table.filter" class="form-control form-control-sm ml-2" type="search"
                                @keyup.enter="setToFirstPageAndRefresh"
                                @input="setToFirstPageAndRefresh"></b-form-input>
                </label>
              </div>
            </div>
          </div>

          <div class="row my-2">
            <div class="col-sm-12 col-md-6">
              <div>
                <b-dropdown dropleft variant="info" class="w-xl">
                  <template slot="button-content">{{ $t('core-orders.legend.button') }}</template>
                  <b-dropdown-item-button>Status po lewej stronie:</b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-11">Osiągnieto stan krytyczny</span></b-dropdown-item-button>
                  <b-dropdown-item-button>Status po prawej stronie:</b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-1">Niezamówiony (nieopłacony) | stan krytyczny: po 2 godz.</span></b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-2">Niezamówiony (opłacony) | stan krytyczny: po 30 min.</span></b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-3">Zamówiony u dostawcy | stan krytyczny: po 2 godz.</span></b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-4">Zarezerwowany u dostawcy | stan krytyczny: po 2 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-5">Opłacony u dostawcy | stan krytyczny: po 2 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-6">Realizacja z magazynu | stan krytyczny: po 1 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-7">Przygotowany do wysyłki - hurtownia | stan krytyczny: po 12 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-8">Wysłany z hurtowni do nas | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-9">Zwrot | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-9">Zwrot przyjęty na magazyn | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-10">Reklamacja | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-12">Wysłany do klienta z hurtowni | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-13">Wysłany do klienta z magazynu | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-14">Poczekalnia | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-15">Gotowy do odbioru | stan krytyczny: po 24 godz.</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-15">Umieszczona w paczkomacie lub punkcie odbioru | stan krytyczny: po 24 godz.</span></b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-12">Odebrana przez kuriera | stan krytyczny: po 24 godz.</span></b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-16">Zwrot odesłany do klienta | stan krytyczny: po 24 godz.</span></b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-17">Zrealizowany</span></b-dropdown-item-button>
                  <b-dropdown-item-button><span class="status-text-18">Anulowany</span></b-dropdown-item-button>
               </b-dropdown>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="float-right">
                <div class="d-inline-flex justify-content-end">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows"
                                    :per-page="table.perPage"></b-pagination>
                    </ul>
                  </div>
                  <div class="align-items-center">
                    <label class="d-inline-flex align-items-center mx-2">
                      <b-form-input v-model="table.inputPage" class="form-control form-control-sm ml-2 form-xs"/>
                    </label>
                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">Idź do
                      strony
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <b-table
                ref="table"
                :items="loadShopOrders"
                :fields="getFields()"
                responsive="sm"
                :per-page="table.perPage"
                :current-page="table.currentPage"
                :sort-by.sync="table.sortBy"
                :sort-desc.sync="table.sortDesc"
                :filter-included-fields="table.filterOn"
                @filtered="elements => paginationHelper.onFiltered(table, elements)"
                :empty-text="$t('message.no-elements')"
                :empty-filtered-text="$t('message.no-records')" :show-empty="true">

              <template v-slot:cell(id)="{ item }">
                <div :class="getProductLeftLabelColor(item)" class="status-data">
                  <span :id="item.id">{{ getIdText(item) }} </span>
                </div>
              </template>

              <template v-slot:cell(totalProductsCostTaxExcluded)="{ item }">
                <div :class="getProductLeftLabelColor(item)" class="status-data">
                  <span>{{ item.totalProductsCostTaxExcluded.toFixed(2) + " PLN" }} </span>
                </div>
              </template>

              <template v-slot:cell(pricesSum)="{ item }">
                <div :class="getProductLeftLabelColor(item)" class="status-data">
                  <span>{{ item.pricesSum.toFixed(2) + " PLN" }} </span>
                </div>
              </template>

              <template v-slot:cell(lastModification)="{ item }">
                <div :class="getProductLeftLabelColor(item)" class="status-data">
                  <template v-if="item.lastModifierEmail">
                    <span>{{ item.lastModifierFirstName }} {{ item.lastModifierLastName }}</span>
                    <br>
                    <span>({{ item.lastModifierEmail }})</span>
                  </template>
                  <template v-else>
                    <span>Brak</span>
                  </template>
                </div>
              </template>

              <template v-slot:cell(action)="{ item }">
                <div :class="getProductRightLabelColor(item)" class="status-data">
                  <div class="actions">
                    <div class="button-items" style="padding-top: 5px;">
                      <a :href="getOrderDetailsUrl(item)" :id="`order-details-${item.id}`" class="btn btn-sm btn-primary border-dark">Szczegóły</a>

                      <template v-if="!item.deleted">
                        <template v-if="item.archived">
                          <b-button @click="archiveOrUnArchiveOrder(item, false)" variant="success" class="btn-sm border-dark">Przywróć</b-button>
                          <!--                        <a @click="archiveOrUnArchiveOrder(item, false)" :id="`unarchive-order-${item.id}`" class="clickable-element mr-4 text-success"><i-->
                          <!--                            class="mdi mdi-backup-restore font-size-22"></i></a>-->
                          <!--                        <b-tooltip :target="`unarchive-order-${item.id}`" placement="right">Przywróć na kartę zamówień</b-tooltip>-->
                        </template>
                        <template v-else>
                          <b-button @click="archiveOrUnArchiveOrder(item, true)" variant="warning" class="btn-sm border-dark">Archiwizuj</b-button>
                          <!--                        <a @click="archiveOrUnArchiveOrder(item, true)" :id="`archive-order-${item.id}`" class="clickable-element mr-4 text-warning"><i-->
                          <!--                            class="mdi mdi-archive font-size-22"></i></a>-->
                          <!--                        <b-tooltip :target="`archive-order-${item.id}`" placement="right">Archiwizuj zamówienie</b-tooltip>-->
                        </template>
                      </template>

                      <template v-if="item.archived">
                        <template v-if="item.deleted">
                          <b-button @click="deleteOrder(item, false)" variant="success" class="btn-sm border-dark">Przywróć do zarchiwizowanych</b-button>
                          <!--                        <a @click="deleteOrder(item, false)" :id="`undelete-order-${item.id}`" class="clickable-element mr-4 text-success"><i-->
                          <!--                            class="mdi mdi-backup-restore font-size-22"></i></a>-->
                          <!--                        <b-tooltip :target="`undelete-order-${item.id}`" placement="right">Przywróć na kartę zamówień zarchiwizowanych</b-tooltip>-->
                        </template>
                        <template v-else>
                          <b-button @click="deleteOrder(item, true)" variant="danger" class="btn-sm border-dark">Usuń zamówienie</b-button>
                          <!--                        <a @click="deleteOrder(item, true)" :id="`delete-order-${item.id}`" class="clickable-element mr-4 text-danger"><i-->
                          <!--                            class="mdi mdi-delete font-size-22"></i></a>-->
                          <!--                        <b-tooltip :target="`delete-order-${item.id}`" placement="right">Usuń zamówienie</b-tooltip>-->
                        </template>
                      </template>

                      <order-address-button :deliveryAddresses="deliveryAddresses" :shopOrderId="item.id" />
                    </div>
                  </div>
                </div>
              </template>

              <template v-slot:cell(paymentStatus)="{ item }">
                <div :class="getProductRightLabelColor(item)" class="status-data">
                                  <span class="badge" :class="paymentStatusColor(item.sbzPaymentStatus)">{{
                                      paymentStatusText(item.sbzPaymentStatus)
                                    }}</span>
                </div>
              </template>

              <template v-slot:row-details="orderTable">
                <b-table :bordered=true :items="getOrderProducts(orderTable.item)"
                         :fields="getProductsFields()"
                         :tbody-tr-class="highlightRow"
                         class="hovered-order"
                         :empty-text="$t('message.no-elements')"
                         :empty-filtered-text="$t('message.no-records')" :show-empty="true">

                  <template v-slot:cell(status)="{ item }">
                    <span :class="orderHelper.getProductStatusColor(item.shopOrderProductStatus)">{{
                        $t('core-orders.product.status.' + item.shopOrderProductStatus)
                      }}</span>
                  </template>

                  <template v-slot:cell(name)="{ item }">
                    <div class="custom-control custom-checkbox">
                      <input :value="item.id" v-model="modals.changeProductStatus.productIds" type="checkbox" class="custom-control-input" :id="`check-${item.id}`"/>
                      <label class="custom-control-label" :for="`check-${item.id}`">&nbsp;</label>
                      <a :href="`/dashboard/admin/product/show/${item.productId}`" target="_blank">{{ item.name }}</a>
                    </div>
                  </template>

                  <template v-slot:cell(warehouse)="{ item }">
                    <a :href="`/dashboard/admin/warehouse/show/${item.warehouseId}`" target="_blank">{{ item.warehouseName }}</a>
                  </template>
                </b-table>
              </template>
            </b-table>
          </div>

          <div class="row">
            <div class="col">
              <div class="float-left">
                <p>{{
                    $t('table.entries-footer', {
                      'amount': paginationHelper.getElementsAmount(table),
                      'elements': paginationHelper.getElementsCount(table),
                      'all': table.totalRows
                    })
                  }}</p>
              </div>

              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="table.currentPage" :total-rows="table.rows"
                                :per-page="table.perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style>
.highlighted-order {
  background-color: var(--order-selected-bg);
}

.default-order {
  background-color: var(--order-normal-bg);
}

.hovered-order tbody tr:hover {
  background-color: var(--order-hover-bg) !important;
}
</style>